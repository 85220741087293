import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {Utils} from "../index";
import {Alert} from "reactstrap";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-white text-gray-900 rounded-lg shadow-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl md:text-4xl font-bold text-center`}
  }
  input {
    ${tw`w-full bg-transparent text-gray-900 text-base font-medium tracking-wide border-b-2 py-2 hocus:border-primary-500 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div`sm:w-5/12 flex flex-col`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm text-gray-600`;
const Input = tw.input``;
const SubmitButton = tw.button`
  sm:w-32 mt-6 py-3 bg-primary-500 text-white rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-primary-700 hover:text-white hocus:-translate-y-px hocus:shadow-xl
  self-end
`;

export default ({ onSubmit, formClient, setFormClient, custom_fields, customFormData, setCustomFormData, errorBooking }) => {
  const [formData, setFormData] = useState({
    name: formClient.name,
    cell: formClient.cell,
    email: formClient.email
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (custom_fields && custom_fields.length > 0) {
      const initialCustomFormData = custom_fields.reduce((acc, field) => {
        const existingField = customFormData.find(item => item.id === field.id);
        acc[field.id] = existingField ? existingField.value : '';
        return acc;
      }, {});
      setFormData(prevData => ({ ...prevData, customFieldsData: initialCustomFormData }));
    }
  }, [custom_fields, customFormData]);

  const handleChange = (e) => {
    let { name, value } = e.target;

    if (name === "cell") {
      if (value[0] === "+") {
        value = "+" + value.slice(1).replace(/\D/g, "");
      } else {
        value = value.replace(/\D/g, "");
      }

      if (value.length >= 3 && value[0] !== "+") {
        value = "+39" + value;
      }
    }

    setFormClient({
      ...formData,
      [name]: value,
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCustomFieldChange = (id, value) => {

    if (custom_fields.find((f) => f.id === id)?.type === "PHONE") {
      if (value[0] === "+") {
        value = "+" + value.slice(1).replace(/\D/g, "");
      } else {
        value = value.replace(/\D/g, "");
      }

      if (value.length >= 3 && value[0] !== "+") {
        value = "+39" + value;
      }
    }

    setCustomFormData(prevState => {
      const fieldIndex = prevState.findIndex(item => item.id === id);
      if (fieldIndex !== -1) {
        const updatedFields = [...prevState];
        updatedFields[fieldIndex].value = value;
        return updatedFields;
      } else {
        return [...prevState, { id, value }];
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let validationErrors = {};

    if (!formData.name) {
      validationErrors.name = "Il nome è obbligatorio";
    }
    if (!formData.cell) {
      validationErrors.cell = "Il numero di telefono è obbligatorio";
    } else if (!Utils.isValidCell(formData.cell)) {
      validationErrors.cell = "Numero di telefono non valido";
    }
    if (formData.email && !Utils.isValidEmail(formData.email)) {
      validationErrors.email = "Email non valida";
    }

    custom_fields.forEach(field => {
      if (field.required && !formData.customFieldsData[field.id]) {
        validationErrors[`customField_${field.id}`] = `Il campo ${field.name} è obbligatorio`;
      }
    });

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      onSubmit(formData);
    }
  };

  const renderCustomField = (field) => {

    if (field.type === "SELECT")
      return (
          <InputContainer key={field.id}>
            <Label htmlFor={`customField_${field.id}`}>{field.name} {field.required && '*'}</Label>
            <select
                id={`customField_${field.id}`}
                name={`customField_${field.id}`}
                value={formData.customFieldsData ? formData.customFieldsData[field.id] : ''}
                onChange={(e) => handleCustomFieldChange(field.id, e.target.value)}
                required={field.required}
            >
              <option value="" disabled>Seleziona {field.name}</option>
              {field.allowed_multiple_values && field.allowed_multiple_values.map((option, idx) => (
                  <option key={idx} value={option}>{option}</option>
              ))}
            </select>
            {errors[`customField_${field.id}`] && <p tw="text-red-500 text-sm">{errors[`customField_${field.id}`]}</p>}
          </InputContainer>
      );


    return (
        <InputContainer key={field.id}>
          <Label htmlFor={`customField_${field.id}`}>{field.name} {field.required && '*'}</Label>
          <Input
              id={`customField_${field.id}`}
              type={field.type}
              name={`customField_${field.id}`}
              placeholder={`Inserisci ${field.name}`}
              value={formData.customFieldsData ? formData.customFieldsData[field.id] : ''}
              onChange={(e) => handleCustomFieldChange(field.id, e.target.value)}
              required={field.required}
          />
          {errors[`customField_${field.id}`] && <p tw="text-red-500 text-sm">{errors[`customField_${field.id}`]}</p>}
        </InputContainer>
    );
  };

  return (
      <Container>
        <Content>
          <FormContainer>
            <div tw="mx-auto max-w-4xl">
              <h3>Compila i dati per la prenotazione</h3>
              <form onSubmit={handleSubmit}>
                <TwoColumn>
                  <Column>
                    <InputContainer>
                      <Label htmlFor="name-input">Nome e Cognome*</Label>
                      <Input
                          id="name-input"
                          type="text"
                          name="name"
                          placeholder="Es. Mario Rossi"
                          value={formData.name}
                          onChange={handleChange}
                          autoComplete="name"
                          required
                      />
                      {errors.name && <p tw="text-red-500 text-sm">{errors.name}</p>}
                    </InputContainer>
                    <InputContainer>
                      <Label htmlFor="cell-input">Numero di Telefono*</Label>
                      <Input
                          id="cell-input"
                          type="tel"
                          name="cell"
                          placeholder="Es. +393203415255"
                          value={formData.cell}
                          onChange={handleChange}
                          autoComplete="tel"
                          required
                      />
                      <small >Inserisci anche il prefisso internazionale (+39) per Italia</small>
                      {errors.cell && <p tw="text-red-500 text-sm">{errors.cell}</p>}
                    </InputContainer>
                  </Column>
                  <Column>
                    <InputContainer tw="flex-1">
                      <Label htmlFor="email-input">Indirizzo Email</Label>
                      <Input
                          id="email-input"
                          type="email"
                          name="email"
                          placeholder="Es. mario.rossi@gmail.com"
                          value={formData.email}
                          onChange={handleChange}
                          autoComplete="email"
                      />
                      {errors.email && <p tw="text-red-500 text-sm">{errors.email}</p>}
                    </InputContainer>
                  </Column>
                </TwoColumn>

                {custom_fields && custom_fields.map(field => renderCustomField(field))}

                <div className="text-center">
                  <SubmitButton className="text-left" type="submit" style={{width: "150px"}}>Conferma</SubmitButton>
                </div>

                {(errorBooking) ? <Alert color="danger" >Si è verificato un errore durante la prenotazione</Alert> : null}

              </form>
            </div>
          </FormContainer>
        </Content>
      </Container>
  );
};
