import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import heroScreenshotImageSrc from "../images/hero-screenshot-1.png";
// import macHeroScreenshotImageSrc from "../images/hero-screenshot-2.png";
// import prototypeIllustrationImageSrc from "../images/prototype-illustration.svg";
// import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
// import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { Header, HeroHomepage, Footer } from "custom";
import ShieldIconImage from "../images/shield-icon.svg";
import SupportIconImage from "../images/support-icon.svg";
import CustomizeIconImage from "../images/customize-icon.svg";
import ReliableIconImage from "../images/reliable-icon.svg";
import FastIconImage from "../images/fast-icon.svg";
import SimpleIconImage from "../images/simple-icon.svg";
import {NavLink, NavLinks, PrimaryLink} from "custom/Components/Header";

export default () => {
    const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
    const HighlightedText = tw.span`text-primary-500`;

    return (
        <AnimationRevealPage>
            <Header
                links={[
                    <NavLinks key={1}>
                        <NavLink href={process.env.REACT_APP_FRONTOFFICE_URL} target="_blank" tw="lg:ml-12!">
                            Accedi
                        </NavLink>
                        <PrimaryLink href="/signup">Prova Gratis</PrimaryLink>
                    </NavLinks>
                ]}
            />
            <HeroHomepage roundedHeaderButton={true} />
            <Features
                description={"Schedula Pro è progettato per rendere la gestione delle prenotazioni e dei pagamenti semplice, sicura e personalizzata."}
                subheading={<Subheading>Caratteristiche</Subheading>}
                heading={
                    <>
                        Abbiamo un Servizio <HighlightedText>Straordinario.</HighlightedText>
                    </>
                }
                cards={[
                    {
                        imageSrc: ShieldIconImage,
                        title: "Sicuro",
                        description: "Collaboriamo solo con fornitori che garantiscono la massima sicurezza per i tuoi dati e transazioni."
                    },
                    {
                        imageSrc: SupportIconImage,
                        title: "Assistenza 24/7",
                        description: "Il nostro team di supporto è sempre disponibile per aiutarti, in qualsiasi momento e ovunque ti trovi."
                    },
                    {
                        imageSrc: CustomizeIconImage,
                        title: "Personalizzabile",
                        description: "Schedula Pro si adatta alle esigenze della tua attività, permettendoti di personalizzarne ogni aspetto."
                    },
                    {
                        imageSrc: ReliableIconImage,
                        title: "Affidabile",
                        description: "Un sistema robusto su cui puoi contare, sempre. Con Schedula Pro, le tue prenotazioni sono al sicuro."
                    },
                    {
                        imageSrc: FastIconImage,
                        title: "Veloce",
                        description: "Gestisci le tue prenotazioni e i tuoi pagamenti in modo rapido ed efficiente, senza intoppi."
                    },
                    {
                        imageSrc: SimpleIconImage,
                        title: "Facile",
                        description: "Semplice da usare, senza competenze tecniche. Concentrati sulla tua attività, alle prenotazioni ci pensiamo noi."
                    },
                ]}
            />
            <MainFeature
                subheading={<Subheading>Lavoro di Qualità</Subheading>}
                heading={
                    <>
                        Progettato e Sviluppato da <HighlightedText>Professionisti.</HighlightedText>
                    </>
                }
                description={"Schedula Pro è il risultato di un'attenta progettazione e sviluppo da parte di esperti del settore. Ogni funzionalità è stata pensata per rendere la gestione delle prenotazioni semplice e potente, garantendo un'esperienza utente impeccabile."}
                imageSrc={heroScreenshotImageSrc}
                primaryButtonText={"Inizia Ora"}
                primaryButtonUrl={"/signup"}
                imageBorder={true}
                imageDecoratorBlob={true}
            />
            <Pricing
                subheading={<Subheading>Prezzi</Subheading>}
                primaryButtonText={"Inizia"}
                heading={
                    <>
                        Scopri i nostri <HighlightedText>Pacchetti</HighlightedText> flessibili.
                    </>
                }
                description={"Scegli il pacchetto che potenzia la tua attività. Abbiamo ciò che serve per far crescere il tuo business."}
                plans={[
                    {
                        name: "Silver",
                        price: "GRATIS",
                        duration: "per sempre",
                        mainFeature: "Per prenotazioni più semplici",
                        features: ["1 Utente", "1 Servizio", "50 Prenotazioni Mensili", "Prenotazioni Online"],
                        primaryButtonText: "Inizia Gratis",
                        href: "/signup"
                    },
                    {
                        name: "Gold",
                        price: "€39,99",
                        duration: "al mese",
                        mainFeature: "Per piccole imprese",
                        features: ["3 Utenti", "10 Servizi", "500 Prenotazioni Mensili", "Prenotazioni Online", "Campagne Promozionali"],
                        featured: true,
                        href: "/signup",
                        primaryButtonText: "Prova Gratis"
                    },
                    {
                        name: "Platinium",
                        price: "€199,99",
                        duration: "al mese",
                        mainFeature: "Per avere il massimo!",
                        features: ["50 Utenti", "100 Servizi", "5000 prenotazioni mensili", "Prenotazioni Online", "Campagne Promozionali", "Analisi e Statistiche"],
                        href: "/signup",
                        primaryButtonText: "Inizia"
                    }
                ]}
            />
            <FAQ
                subheading={<Subheading>FAQS</Subheading>}
                heading={
                    <>
                        Hai qualche <HighlightedText>Domanda ?</HighlightedText>
                    </>
                }
                description={""}
                faqs={[
                    {
                        question: "Cos'è Schedula Pro e a chi si rivolge?",
                        answer:
                            "Schedula Pro è un software innovativo per la gestione delle prenotazioni, ideale per piccole e medie imprese come parrucchieri, studi medici, centri estetici e gestori di campi sportivi. Che tu gestisca le prenotazioni online o offline, Schedula Pro rende tutto semplice e intuitivo, permettendoti di concentrarti sul tuo business."
                    },
                    {
                        question: "Come posso gestire i pagamenti con Schedula Pro?",
                        answer:
                            "Con Schedula Pro, puoi gestire facilmente i flussi economici dei pagamenti sia per le prenotazioni online che per quelle offline. Integrando diverse opzioni di pagamento, il software ti aiuta a mantenere tutto sotto controllo, riducendo al minimo gli sforzi amministrativi."
                    },
                    {
                        question: "Schedula Pro è adatto alla mia attività?",
                        answer:
                            "Se la tua attività accetta prenotazioni, Schedula Pro è la soluzione perfetta per te! Che tu gestisca un salone di bellezza, uno studio medico, un campo sportivo o qualsiasi altra attività che richiede la gestione delle prenotazioni, Schedula Pro si adatta alle tue esigenze specifiche."
                    },
                    {
                        question: "È difficile configurare e utilizzare Schedula Pro?",
                        answer:
                            "Assolutamente no! Schedula Pro è stato progettato per essere user-friendly. La configurazione è semplice e veloce, e grazie alla nostra interfaccia intuitiva, sarai operativo in pochi minuti, senza bisogno di competenze tecniche avanzate."
                    },
                    {
                        question: "Posso provare Schedula Pro prima di acquistarlo?",
                        answer:
                            "Certo! Offriamo una prova gratuita di Schedula Pro su tutti i pacchetti per permetterti di testare tutte le sue funzionalità senza alcun impegno. Scopri come Schedula Pro può trasformare la gestione delle prenotazioni nella tua attività, con zero rischi."
                    }
                ]}
            />
            <GetStarted
                text="Il tempo è l'unica cosa che non possiamo riavere. Usalo saggiamente."
                subText={"Steve Jobs"}
                primaryLinkText = "Inizia Ora"
                primaryLinkUrl = "/signup"
                secondaryLinkText = "Contattaci"
                secondaryLinkUrl = "/contact"
            />
            <Footer />
        </AnimationRevealPage>
    );
}
