import _Loading from './Loading';
import _Utils from './Utils';
import _Params from './Params';
import _AtomWebhook from './AtomWebhook';

import _Header from './Components/Header';
import _HeroHomepage from './Components/HeroHomepage';
import _Footer from './Components/Footer';
import _PrintList from './Components/PrintList';
import _FormBooking from './Components/FormBooking';
import _CalendarBooking from './Components/CalendarBooking';



export const Loading = _Loading;
export const Utils = _Utils;
export const Params = _Params;
export const AtomWebhook = _AtomWebhook;

export const Header = _Header;
export const HeroHomepage = _HeroHomepage;
export const Footer = _Footer;
export const PrintList = _PrintList;
export const FormBooking = _FormBooking;
export const CalendarBooking = _CalendarBooking;
