import React, { useState } from "react";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import '../../styles/CustomCalendar.css';
import moment from "moment";
import tw from "twin.macro";
import styled from "styled-components";
import {Spinner} from "reactstrap";


const Title = tw.h2`text-xl md:text-2xl lg:text-3xl font-bold text-center mb-6`;

const LoadingContainer = tw.div`flex justify-center items-center h-full`;


const StyledCalendar = styled(Calendar)`
  &.react-calendar {
    width: 100%;
    max-width: 500px;
    background-color: white;
    border: none;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.1);
    border-radius: 10px;
    padding: 1rem;
    font-family: 'Arial', sans-serif;

    button.react-calendar__navigation__arrow:disabled {
      display: none;
    }

    .react-calendar__navigation button {
      color: var(--primary-color);
      font-size: 1.25rem;
      background-color: transparent;
      border: none;
      padding: 0.5rem;
    }

    .react-calendar__navigation button:enabled:hover {
      background-color: #f0f0f0;
      color: var(--primary-color);
    }
`;


const CalendarWrapper = styled.div`
  --primary-color: ${({ colorScheme }) => colorScheme || "#1d72b8"};
  ${tw`flex flex-col items-center mt-8 lg:m-5 lg:p-5`}
`;

const CalendarBooking = ({ daysAvailable, onDateSelect, title, colorScheme, loading }) => {
    const [selectedDate, setSelectedDate] = useState(null);

    const isDateAvailable = (date) => {
        return daysAvailable.includes(moment(date).format("YYYY-MM-DD"));
    };

    const handleDateChange = (date) => {
        const formattedDate = moment(date).format("YYYY-MM-DD");
        setSelectedDate(formattedDate);
        onDateSelect(formattedDate);
    };

    return (
        <CalendarWrapper colorScheme={colorScheme}>
            <Title>{title}</Title>
            {loading ? (
                <LoadingContainer>
                    <Spinner color="primary" />
                </LoadingContainer>
            ) : (
                <StyledCalendar
                    value={selectedDate ? new Date(selectedDate) : null}
                    tileDisabled={({ date }) => !isDateAvailable(date)}
                    onClickDay={handleDateChange}
                    minDate={new Date()}
                    locale="it-IT"
                    minDetail="month"
                    next2Label={null}
                    prev2Label={null}
                />
            )}
        </CalendarWrapper>
    );
};

export default CalendarBooking;
