


import React, { useEffect, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import {Header, HeroHomepage, Footer, Loading, PrintList, FormBooking, CalendarBooking} from "custom";
import MainFeature1 from "components/features/TwoColWithButton.js";
import Features from "components/features/ThreeColSimple.js";
import { NavLink, NavLinks, PrimaryLink } from "custom/Components/Header";
import {BookingAPI} from "api";
import {useNavigate, useParams} from "react-router-dom";

import 'moment/locale/it'
import {Alert, Spinner} from "reactstrap";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const LoadingContainer = tw.div`flex justify-center items-center h-full`;


const moment = require("moment")

const HomeService = () => {

    const [daysAvailable, setDaysAvailable] = useState([]);
    const [slots, setSlots] = useState([]);
    const [loading, setLoading] = useState(true);

    const [customFormData, setCustomFormData] = useState([]);

    const [company, setCompany] = useState(null);
    const [service, setService] = useState(null);

    const [errorBooking, setErrorBooking] = useState(false);

    const [loadingBooking, setLoadingBooking] = useState(false);


    const navigate = useNavigate();

    const [form, setForm] = useState({
        hour: null,
        day: null,
        client: {
            name: "",
            email: "",
            cell: ""
        }
    });

    const [selectedDay, setSelectedDay] = useState(moment().add(1, "day").format("YYYY-MM-DD"));

    const {slug_company, slug_service} = useParams();


    useEffect(() => {
        getCompany();
    }, []);

    // useEffect(() => {
    //     getSlots();
    // }, [selectedDay]);

    let getCompany = async () =>  {
        try {
            let companyRes = await BookingAPI.getCompany(slug_company);
            setCompany(companyRes.company)
            getDays();
        } catch (e) {
            console.error(e)
        }
    }

    let getDays = async () => {
        try {
            let daysRes = await BookingAPI.getDays(slug_company, slug_service);

            setService(daysRes.service)
            setDaysAvailable(daysRes.days)
            setLoading(false);
        } catch (e) {
            console.error(e)
        }
    }

    let getSlots = async (selectedDay) => {
        try {
            let slotsRes = await BookingAPI.getSlots(slug_company, slug_service, selectedDay);
            setSlots(slotsRes.slots)

            setForm((prev) => {
                return {
                    ...prev,
                    day: selectedDay
                }
            })
            setLoading(false);
        } catch (e) {
            console.error(e)
        }
    }

    let handleClickSlot = (slot) => {

        setLoading(true);

        if (form.day) {
            setForm((prev) => {
                return {
                    ...prev,
                    hour: slot
                }
            })
        } else {
            getSlots(slot)
        }
    }

    let handleBackClick = () => {
        setLoading(true);

        if (form.day) {
            setForm((prev) => {
                return {
                    ...prev,
                    day: null,
                    hour: null
                }
            })
            getDays()
        }
    }

    let onSubmit = () => {

        setLoadingBooking(true)

        form.date_time = form.day + " " + form.hour;

        form.custom_fields = customFormData;

        BookingAPI.checkout(slug_company, slug_service, form).then((hash_booking) => {
            navigate("/booking/" + hash_booking, {replace: true})
        }).catch((err) => {
            setErrorBooking(true)
            setLoadingBooking(false)
        })

    }

    let setFormClient = (client) => {
        setForm((prev) => {
            return {
                ...prev,
                client: client
            }
        });
    }


    return (
        <AnimationRevealPage>
            <Header
                logo={{
                    src: company?.logo,
                    link: "/" + slug_company,
                    name: company?.name
                }}
                links={[]}
            />

            <MainFeature1
                imageSrc={service?.image}
                heading={service?.name}
                description={service?.description}
                buttonRounded={false}
            />

            {(daysAvailable.length > 0) ? ((form.day && form.hour) ? <PrintList
                slots={[]}
                typeSlot={"DAY"}
                title={<>{moment(form.day, "YYYY-MM-DD").format("dddd DD MMM")}<br />{form.hour}</>}
                onSlotBack={(form.day) ? handleBackClick : undefined}
                labelSlotBack = "Cambia data"
            /> : ((!form.day) ? <CalendarBooking
                onDateSelect={handleClickSlot}
                daysAvailable={daysAvailable}
                title={"Scegli un giorno disponibile"}
                loading={loading}
                colorScheme={service?.color_scheme}
            /> : <PrintList
                slots={slots}
                typeSlot={"HOUR"}
                title={moment(form.day, "YYYY-MM-DD").format("dddd DD MMM")}
                loading={loading}
                onSlotClick={handleClickSlot}
                colorScheme={service?.color_scheme}
                onSlotBack={(form.day) ? handleBackClick : undefined}
                labelSlotBack = "Cambia data"
            />)) : (loading) ? <Loading /> : <Alert color="warning" >Non ci sono giorni disponibili per la prenotazione</Alert>}

            {(form.day && form.hour) ? ((loadingBooking) ? <Loading /> : <FormBooking
                onSubmit={onSubmit}
                formClient={form.client}
                setFormClient={setFormClient}
                errorBooking={errorBooking}
                custom_fields={service.custom_fields}
                customFormData={customFormData}
                setCustomFormData={setCustomFormData}
            />) : null}

            <Footer />
        </AnimationRevealPage>
    );
};

export default HomeService;
