import React, { useEffect, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import {Header, HeroHomepage, Footer, Loading} from "custom";
import MainFeature1 from "components/features/TwoColWithButton.js";
import Features from "components/features/ThreeColSimple.js";
import { NavLink, NavLinks, PrimaryLink } from "custom/Components/Header";
import {BookingAPI} from "api";
import {useNavigate, useParams} from "react-router-dom";

const Subheading = tw.span`uppercase tracking-wider text-sm`;

const HomeService = () => {

    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true);

    const [company, setCompany] = useState(null);

    const {slug_company} = useParams();

    useEffect(() => {
        getCompany();
    }, []);

    let getCompany = async () =>  {
        try {
            let companyRes = await BookingAPI.getCompany(slug_company);
            setCompany(companyRes.company)

            setServices(companyRes.services.map((s) => {
                return {
                    heading: s.name,
                    description: s.description,
                    imageSrc: s.image,
                    primaryButtonText: "Prenota Online",
                    primaryButtonUrl: "/" + slug_company + "/" + s.slug
                }
            }));
            setLoading(false);
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <AnimationRevealPage>
            <Header
                logo={{
                    src: company?.logo,
                    link: "/" + slug_company,
                    name: company?.name
                }}
                links={[
                    <NavLinks key={1}>
                        <NavLink href="/" target="_blank" tw="lg:ml-12!">
                            Scopri Schedula Pro
                        </NavLink>
                        <PrimaryLink href={process.env.REACT_APP_FRONTOFFICE_URL} target="_blank">
                            Accedi
                        </PrimaryLink>
                    </NavLinks>
                ]}
            />

            <MainFeature1
                imageSrc={company?.image}
                description={company?.description}
                buttonRounded={false}
            />

            {services.map((service, index) => (
                <MainFeature1
                    key={index}
                    subheading=""
                    imageSrc={service.imageSrc}
                    description={service.description}
                    heading={service.heading}
                    primaryButtonText={service.primaryButtonText || null}
                    primaryButtonUrl={service.primaryButtonUrl}
                    buttonRounded={false}
                    textOnLeft={(index % 2 === 1)}
                />
            ))}

            <Footer />
        </AnimationRevealPage>
    );
};

export default HomeService;
