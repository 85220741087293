let sendLead = function (body) {
    return new Promise(function (resolve, reject) {
        const urlWeebhook = process.env.REACT_APP_URL_WEBHOOK_LEAD

        fetch(urlWeebhook, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(body)
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('signup lead sent', responseJson);
                if (responseJson.status === "LEAD_CREATED")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
}

const AtomWebhook = {
    sendLead: sendLead
};

module.exports = AtomWebhook;
