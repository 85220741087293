import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container as ContainerBase } from "components/misc/Layouts";
import signup_illustration from "../images/signup-illustration.png";
import logo from "../images/logo.svg";
import { AtomWebhook, Loading } from "custom";
import { Alert } from "reactstrap";

const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-lg bg-contain bg-center bg-no-repeat`}
`;

export default function Signup({
                                 logoLinkUrl = "/",
                                 illustrationImageSrc = signup_illustration,
                                 headingText = "Registrazione",
                                 submitButtonText = "Accedi alla Prova Gratuita",
                                 tosUrl = "/termini-e-condizioni",
                                 privacyPolicyUrl = "/privacy-policy"
                               }) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    business_name: ""
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    let { name, value } = e.target;

    if (name === "phone") {
      if (value[0] === "+") {
        value = "+" + value.slice(1).replace(/\D/g, "");
      } else {
        value = value.replace(/\D/g, "");
      }

      if (value.length >= 3 && value[0] !== "+") {
        value = "+39" + value;
      }
    }

    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    AtomWebhook.sendLead(formData).then((res) => {
      setLoading(false);
      setIsSubmitted(true);
    }).catch((err) => {
      console.error(err);
      setLoading(false);
      setError(true);
    });
  };

  if (isSubmitted) {
    return (
        <Container>
          <Content>
            <MainContainer>
              <LogoLink href={logoLinkUrl}>
                <LogoImage src={logo} />
              </LogoLink>
              <MainContent>
                <Heading>Grazie per esserti registrato!</Heading>
                <p tw="mt-6 text-sm text-gray-600 text-center">
                  A breve riceverai un'email con il link per accedere alla prova gratuita di Schedula Pro.
                </p>
              </MainContent>
            </MainContainer>
            <IllustrationContainer>
              <IllustrationImage imageSrc={illustrationImageSrc} />
            </IllustrationContainer>
          </Content>
        </Container>
    );
  }

  if (loading) {
    return <Loading />;
  }

  return (
      <Container>
        <Content>
          <MainContainer>
            <LogoLink href={logoLinkUrl}>
              <LogoImage src={logo} />
            </LogoLink>
            <MainContent>
              <Heading>{headingText}</Heading>
              <FormContainer>
                <Form onSubmit={handleSubmit}>
                  <Input
                      type="text"
                      name="name"
                      placeholder="Nome e cognome*"
                      autoComplete="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                  />
                  <Input
                      type="text"
                      name="business_name"
                      placeholder="Nome Attività*"
                      autoComplete="organization"
                      value={formData.business_name}
                      onChange={handleChange}
                      required
                  />
                  <Input
                      type="tel"
                      name="phone"
                      autoComplete="tel"
                      placeholder="Numero di Telefono*"
                      value={formData.phone}
                      onChange={handleChange}
                      required
                  />
                  <Input
                      type="email"
                      name="email"
                      autoComplete="email"
                      placeholder="Indirizzo Email*"
                      value={formData.email}
                      onChange={handleChange}
                      required
                  />
                  <p tw="mt-6 text-xs text-gray-600 text-center">
                    Registrandoti accetti i <a href={tosUrl} target="_blank" tw="border-b border-gray-500 border-dotted">
                    Termini e Condizioni
                  </a> e <a href={privacyPolicyUrl} target="_blank" tw="border-b border-gray-500 border-dotted">
                    Privacy Policy
                  </a> di Schedula Pro.
                  </p>
                  <SubmitButton type="submit">
                    <span className="text">{submitButtonText}</span>
                  </SubmitButton>
                  <p tw="mt-6 text-xs text-gray-600 text-center">
                    La prova gratuita non richiede l'inserimento di una carta di pagamento.
                  </p>
                  {(error) && <Alert color="danger">Si è verificato un errore durante la registrazione</Alert>}
                </Form>
              </FormContainer>
            </MainContent>
          </MainContainer>
          <IllustrationContainer>
            <IllustrationImage imageSrc={illustrationImageSrc} />
          </IllustrationContainer>
        </Content>
      </Container>
  );
}
