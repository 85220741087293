import React, {useEffect, useState} from "react";
import GlobalStyles from 'styles/GlobalStyles';
import { css } from "styled-components/macro"; //eslint-disable-line

import 'bootstrap/dist/css/bootstrap.min.css';

/*
 * This is the entry point component of this project. You can change the below exported default App component to any of
 * the prebuilt landing page components by uncommenting their import and export lines respectively.
 * See one of the landing page components to better understand how to import and render different components (Always
 * make sure if you are building your own page, the root component should be the AnimationRevealPage component. You can
 * disable the animation by using the disabled prop.
 *
 * The App component below is using React router to render the landing page that you see on the live demo website
 * and the component previews.
 *
 */

/* Use AnimationRevealPage as a wrapper component for your pages if you are building a custom one yourself */
// import AnimationRevealPage from "helpers/AnimationRevealPage.js";

/*
 * Hero section is the top most section on the page. It contains the header as well.
 * So you dont need to import headers
 * separately
 */


import HomeStructure from "pages/HomeStructure.js";


import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import {checkVers} from "./CheckBuildService";

import Homepage from "./pages/Homepage";
import Signup from "./pages/Signup";
import {Loading, Utils} from "custom";
import {AuthAPI} from "api";
import ServerError from "./pages/ServerError";
import HomeService from "./pages/HomeService";

import BookingDetail from "./pages/BookingDetail";

import TermsOfServicePage from "./pages/TermsOfService"
import PrivacyPolicyPage from "./pages/PrivacyPolicy"
import CookiePolicyPage from "./pages/CookiePolicy"

global.checkAuth = async function (status) {
    if (status === "UNAUTHORIZED") {
        try {
            await AuthAPI.logout();
        } catch(e) {
            localStorage.removeItem('hash');
            sessionStorage.removeItem("userData");
            global.userData = undefined;
            window.location.replace('/');
        }
    }
};

export default function App() {
    const [loading, setLoading] = useState(true);

    const [error, setError] = useState(false);

    useEffect(() => {
        checkVers().then(() => {
            Utils.checkConnection().then(async () => {
                setLoading(false);
            }).catch((err) => {
                console.error(err)
                setLoading(false);
                setError(true)
            })
        }).catch((err) => {
            console.error(err)
            window.location.reload();
        })
    }, []);

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <>
            <GlobalStyles />
            <ServerError />
        </>;
    }


  return (
    <>
      <GlobalStyles />
      <Router>
        <Routes>
          {/*<Route path="/components/:type/:subtype/:name" element={<ComponentRenderer />} />*/}
          {/*<Route path="/components/:type/:name" element={<ComponentRenderer />} />*/}

            <Route path="/signup" element={<Signup />} />
            <Route path="/cookie-policy" element={<CookiePolicyPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/termini-e-condizioni" element={<TermsOfServicePage />} />
            <Route path="/" element={<Homepage />} />
            <Route path="/booking/:hash_booking" element={<BookingDetail />} />
            <Route path="/:slug_company" element={<HomeStructure />} />
            <Route path="/:slug_company/:slug_service" element={<HomeService />} />
        </Routes>
      </Router>
    </>
  );
}
