import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import {Footer} from "custom";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Termini e Condizioni" }) => {
  return (
      <AnimationRevealPage>
        <Container>
          <ContentWithPaddingXl>
            <HeadingRow>
              <Heading>{headingText}</Heading>
            </HeadingRow>
            <Text>

              <p>Ti invitiamo a leggere attentamente questi Termini e Condizioni prima di utilizzare il nostro Servizio.</p>

              <h1>Descrizione del Servizio</h1>
              <p>
                Schedula Pro è un servizio fornito da FB Web Studio, che permette la gestione di prenotazioni online per professionisti e aziende. Il servizio consente agli utenti di configurare servizi, utenti, e ricevere prenotazioni online, a seconda del pacchetto di abbonamento scelto.
              </p>

              <h1>Piani di Abbonamento</h1>
              <p>
                Schedula Pro offre tre piani di abbonamento, ognuno con specifiche funzionalità e limiti:
              </p>
              <ul>
                <li>
                  <strong>Silver (GRATIS - per sempre):</strong> Adatto per la gestione di prenotazioni semplici.
                  <ul>
                    <li>1 Utente</li>
                    <li>1 Servizio</li>
                    <li>50 Prenotazioni mensili</li>
                    <li>Prenotazioni Online</li>
                  </ul>
                </li>
                <li>
                  <strong>Gold (€39,99 al mese):</strong> Ideale per piccole imprese, con una prova gratuita di 14 giorni.
                  <ul>
                    <li>3 Utenti</li>
                    <li>10 Servizi</li>
                    <li>500 Prenotazioni mensili</li>
                    <li>Prenotazioni Online</li>
                    <li>Campagne Promozionali</li>
                  </ul>
                </li>
                <li>
                  <strong>Platinum (€199,99 al mese):</strong> Per ottenere il massimo dalle funzionalità.
                  <ul>
                    <li>50 Utenti</li>
                    <li>100 Servizi</li>
                    <li>5000 Prenotazioni mensili</li>
                    <li>Prenotazioni Online</li>
                    <li>Campagne Promozionali</li>
                    <li>Analisi e Statistiche avanzate</li>
                  </ul>
                </li>
              </ul>

              <h1>Account Stripe per Ricevere Pagamenti Online</h1>
              <p>
                Per tutti i pacchetti di Schedula Pro, è necessario sottoscrivere un account Stripe per poter accettare pagamenti online per le prenotazioni. La creazione di un account Stripe è una procedura semplice che può essere completata tramite una guida interna alla piattaforma.
              </p>

              <h1>Prova Gratuita</h1>
              <p>
                Il piano Gold offre una prova gratuita di 14 giorni durante la quale un formatore coach si occuperà di configurare i primi servizi per un utilizzo rapido. Durante il periodo di prova, non è richiesto alcun metodo di pagamento; le informazioni di pagamento saranno richieste solo allo scadere dei 14 giorni.
              </p>

              <h1>Limitazioni del Servizio</h1>
              <p>
                Gli utenti che utilizzano il piano Silver sono soggetti a limiti specifici, come il numero di prenotazioni mensili e i servizi configurabili. Per rimuovere tali limitazioni e accedere a funzionalità avanzate, è possibile passare a un piano Gold o Platinum.
              </p>

              <h1>Contatti</h1>
              <p>Per qualsiasi domanda o supporto, puoi contattarci all'indirizzo email: <a href="mailto:support@schedulapro.com">supporto@schedulapro.com</a>.</p>

              <p><small>Ultimo aggiornamento: Settembre 2024</small></p>

            </Text>
          </ContentWithPaddingXl>
        </Container>
        <Footer />
      </AnimationRevealPage>
  );
};
