import React from 'react';
import { Container, Row, Col, Spinner } from 'reactstrap';

import "../../src/styles/custom.css"

const Loading = ({ loadingText = "Caricamento..." }) => {
    return (
        <Container fluid className="d-flex justify-content-center align-items-center vh-100" style={{ backgroundColor: 'white' }}>
            <Row>
                <Col className="text-center">
                    <Spinner style={{ width: '3rem', height: '3rem' }} color="primary" />
                    <br/>
                    <h5 className="mt-3">{loadingText}</h5>
                </Col>
            </Row>
        </Container>
    );
};

export default Loading;
