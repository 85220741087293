import React from "react";
import { createRoot } from 'react-dom/client';
import App from "./App";
import Modal from "react-modal";

import "./routesAPI.js";

Modal.setAppElement("#root");

document.addEventListener('gesturestart', function (e) {
    e.preventDefault();
});

if (process.env.REACT_APP_NODE_ENV === "production") {
    console.log('%cWelcome to Schedula Pro', 'color: #ff6f00; font-size: 24px; font-weight: bold; text-shadow: 2px 2px 4px #000000; background-color: #ffd54f; padding: 10px;');
    console.log('%cDeveloped by FB Web Studio', 'color: #4caf50; font-size: 18px; font-weight: bold;');

    document.addEventListener("contextmenu", function (e) {
        e.preventDefault();
    });
    console.log = function() {};
    console.error = function() {};
}


const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
