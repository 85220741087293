import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {Footer} from "custom";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Privacy Policy" }) => {
  return (
      <AnimationRevealPage>
        <Container>
          <ContentWithPaddingXl>
            <HeadingRow>
              <Heading>{headingText}</Heading>
            </HeadingRow>
            <Text>
              <p>
                Questa Privacy Policy descrive le nostre politiche e procedure sulla raccolta, l'uso e la divulgazione delle tue informazioni quando utilizzi il nostro Servizio, e spiega i tuoi diritti alla privacy e come la legge ti protegge.
              </p>

              <p>
                Utilizziamo i tuoi dati personali per fornire e migliorare il Servizio. Utilizzando il Servizio, acconsenti alla raccolta e all'uso delle informazioni in conformità con questa Privacy Policy.
              </p>

              <h1>Interpretazione e Definizioni</h1>
              <h2>Interpretazione</h2>
              <p>
                Le parole il cui primo carattere è maiuscolo hanno significati definiti nelle seguenti condizioni. Le definizioni avranno lo stesso significato indipendentemente dal fatto che appaiano al singolare o al plurale.
              </p>

              <h2>Definizioni</h2>
              <p>Ai fini di questa Privacy Policy:</p>
              <ul>
                <li><strong>Tu</strong> indica la persona fisica che accede o utilizza il Servizio.</li>
                <li><strong>Azienda</strong> (indicata come "Noi", "Ci" o "Nostro" in questo accordo) si riferisce a FB Web Studio.</li>
                <li><strong>Servizio</strong> indica il sito web Schedulapro.com.</li>
                <li><strong>Cookie</strong> sono piccoli file memorizzati sul tuo dispositivo (computer o dispositivo mobile).</li>
                <li><strong>Dati personali</strong> indica qualsiasi informazione che si riferisce a una persona fisica identificabile.</li>
              </ul>

              <h1>Raccolta e Utilizzo dei Dati Personali</h1>
              <h2>Tipi di Dati Raccolti</h2>

              <h3>Dati Personali</h3>
              <p>
                Quando utilizzi il nostro Servizio, potremmo richiederti di fornire alcune informazioni personali identificabili che possono essere utilizzate per contattarti o identificarti, come:
              </p>
              <ul>
                <li>Email</li>
                <li>Nome e cognome</li>
                <li>Nome attività</li>
                <li>Numero di telefono</li>
                <li>Indirizzo, città, CAP, stato/provincia</li>
              </ul>

              <h3>Cookies</h3>
              <p>
                Utilizziamo i cookie e tecnologie simili per tracciare l'attività sul nostro Servizio e memorizzare determinate informazioni. Puoi rifiutare l'uso dei cookie tramite le impostazioni del tuo browser.
              </p>

              <h1>Uso dei Dati Personali</h1>
              <p>I dati personali che raccogliamo possono essere utilizzati per i seguenti scopi:</p>
              <ul>
                <li>Per fornire e mantenere il nostro Servizio</li>
                <li>Per gestire il tuo account e offrirti accesso alle funzionalità riservate agli utenti registrati</li>
                <li>Per contattarti in merito a comunicazioni relative al Servizio</li>
              </ul>

              <h1>Sicurezza dei Dati Personali</h1>
              <p>
                La sicurezza dei tuoi dati personali è importante per noi, ma ricorda che nessun metodo di trasmissione su Internet è sicuro al 100%. Facciamo del nostro meglio per proteggere i tuoi dati, ma non possiamo garantire la loro assoluta sicurezza.
              </p>

              <h1>Modifiche alla Privacy Policy</h1>
              <p>
                Potremmo aggiornare la nostra Privacy Policy di tanto in tanto. Ti avviseremo tramite email o un avviso ben visibile sul nostro Servizio prima che tali modifiche diventino effettive.
              </p>

              <p><small>Ultimo aggiornamento: Settembre 2024</small></p>

            </Text>
          </ContentWithPaddingXl>
        </Container>
        <Footer />
      </AnimationRevealPage>
  );
};
