let getCompany = function(name_code) {
    return new Promise(function(resolve, reject) {

        fetch(global.route.structure + name_code, {
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('detail company ok', responseJson);
                if (responseJson.status === "DETAIL_COMPANY")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let getDays = function(name_code, service_slug) {
    return new Promise(function(resolve, reject) {

        fetch(global.route.getDays + name_code + "/" + service_slug, {
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('available slot ok', responseJson);
                if (responseJson.status === "LIST_AVAILABLE_DAYS")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};

let getSlots = function(name_code, service_slug, day) {
    return new Promise(function(resolve, reject) {

        fetch(global.route.getSlots + name_code + "/" + service_slug + "?day=" + day, {
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('available slots ok', responseJson);
                if (responseJson.status === "LIST_AVAILABLE_SLOTS")
                    resolve(responseJson);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};


let checkout = function(name_code, service_slug, body) {
    return new Promise(function(resolve, reject) {

        fetch(global.route.checkout + name_code + "/" + service_slug, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify(body)
        })
        .then((response) => (response.status !== 500) ? response.json() : reject())
        .then((responseJson) => {
            global.checkAuth(responseJson.status);
            console.log('booking create', responseJson);
            if (responseJson.status === "BOOKING_CREATED")
                resolve(responseJson.hash_booking);
            else
                reject(responseJson);
        })
        .catch((e) => {
            console.error(e.message);
            reject('APP_ERROR');
        });
    });
};

let getDetail = function(hash_booking) {
    return new Promise(function(resolve, reject) {

        fetch(global.route.getBooking + "?hash_booking=" + hash_booking, {
            method: 'GET'
        })
            .then((response) => (response.status !== 500) ? response.json() : reject())
            .then((responseJson) => {
                global.checkAuth(responseJson.status);
                console.log('detail booking ok', responseJson);
                if (responseJson.status === "DETAIL_BOOKING")
                    resolve(responseJson.booking);
                else
                    reject(responseJson);
            })
            .catch((e) => {
                console.error(e.message);
                reject('APP_ERROR');
            });
    });
};


const BookingAPI = {
    getCompany: getCompany,
    getDays: getDays,
    getSlots: getSlots,
    checkout: checkout,
    getDetail: getDetail
};

module.exports = BookingAPI;
