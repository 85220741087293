import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { Spinner } from "reactstrap";
import moment from "moment/moment";
import {Subheading as SubheadingBase} from "../../components/misc/Headings";

const Container = tw.div`relative p-4 md:p-8 lg:p-12 bg-gray-100 flex flex-col items-center`;
const Title = tw.h2`text-xl md:text-2xl lg:text-3xl font-bold text-center mb-6`;
const SlotsWrapper = tw.div`w-full max-w-4xl`;
const SlotsContainer = styled.div`
  ${tw`flex flex-col items-center md:grid md:grid-cols-2 lg:grid-cols-3 gap-6 p-4 bg-white rounded-lg shadow-lg`}
`;

const Slot = styled.div`
  ${tw`w-full px-6 py-3 border border-gray-300 rounded-lg text-center text-lg md:text-xl cursor-pointer transition duration-300`}
  background-color: ${({ colorScheme }) => colorScheme || 'text-primary-500'};
  color: white;

  &:hover {
    ${tw`shadow-xl transform scale-105`}
  }
`;

const LoadingContainer = tw.div`flex justify-center items-center h-full`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;


export default ({
                    title = "Available Slots",
                    slots = [],
                    colorScheme = null,
                    loading = false,
                    typeSlot = "DAY",
                    onSlotClick = () => {},
                    onSlotBack = null,
                    labelSlotBack = ""
                }) => {
    return (
        <Container>
            {(Boolean(onSlotBack)) ? <Subheading onClick={onSlotBack} style={{cursor: "pointer"}}>{labelSlotBack}</Subheading> : null}
            <Title>{title}</Title>
            <SlotsWrapper>
                {loading ? (
                    <LoadingContainer>
                        <Spinner color="primary" />
                    </LoadingContainer>
                ) : (slots.length > 0) ? <SlotsContainer>
                        {slots.map((slot, index) => (
                            <Slot
                                key={index}
                                colorScheme={colorScheme}
                                onClick={() => onSlotClick(slot)}
                            >
                                <b>{(typeSlot === "HOUR") ? slot : moment(slot, "YYYY-MM-DD").format("dddd DD MMM")}</b>
                            </Slot>
                        ))}
                    </SlotsContainer> : null}
            </SlotsWrapper>
        </Container>
    );
};
