
let server = process.env.REACT_APP_API_URL;

let frontendRoute = process.env.REACT_APP_FRONTEND_URL;

let productionMode = (process.env.REACT_APP_NODE_ENV === "production"); // valorizzare in TRUE per produzione
let versionApp = process.env.REACT_APP_VERSION; //Valorizzare per cambiare versione applicazione
let emailAssistance = process.env.REACT_APP_API_EMAIL_ASSISTANCE; //email assistenza
let route = {
  test: '/test',

  structure: "/booking/company/",
  getDays: "/booking/days/",
  getSlots: "/booking/slots/",
  checkout: "/booking/checkout/",

  getBooking: "/booking",



};



for (var i in route) {
  route[i] = server + route[i];
}
global.server = server;
global.frontend = frontendRoute;
global.productionMode = productionMode;
global.versionApp = versionApp;
global.emailAssistance = emailAssistance;
global.route = route;

