import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {Footer} from "custom";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Cookie Policy" }) => {
  return (
      <AnimationRevealPage>
        <Container>
          <ContentWithPaddingXl>
            <HeadingRow>
              <Heading>{headingText}</Heading>
            </HeadingRow>
            <Text>
              <h1>Cosa sono i Cookie?</h1>
              <p>
                I cookie sono piccoli file di testo che vengono inviati al tuo dispositivo quando visiti un sito web. Vengono utilizzati per diversi scopi, tra cui il monitoraggio delle sessioni, il miglioramento della navigazione e la raccolta di informazioni anonime sulle visite al sito.
              </p>

              <h1>Tipologie di Cookie Utilizzati</h1>
              <ul>
                <li>
                  <p><strong>Cookie Tecnici: </strong></p>
                  <p>
                    Questi cookie sono essenziali per il funzionamento del sito e non richiedono il consenso dell'utente. Sono utilizzati per autenticare gli utenti e garantire la sicurezza delle sessioni.
                  </p>
                </li>
                <li>
                  <p><strong>Cookie di Analisi: </strong></p>
                  <p>
                    Utilizzati per raccogliere informazioni anonime e aggregate sul traffico del sito e sulle modalità di utilizzo, ad esempio tramite Google Analytics.
                  </p>
                </li>
                <li>
                  <p><strong>Cookie di Terze Parti: </strong></p>
                  <p>
                    Questi cookie vengono impostati da servizi esterni, come i social media o sistemi di pagamento. Possono essere utilizzati per tracciare gli utenti e fornire annunci personalizzati.
                  </p>
                </li>
              </ul>

              <h1>Gestione dei Cookie</h1>
              <p>
                Gli utenti possono gestire le preferenze relative ai cookie direttamente dal proprio browser e, se necessario, impedire l'installazione di cookie di terze parti. Tuttavia, disabilitare i cookie potrebbe influire negativamente sull'esperienza di navigazione.
              </p>

              <h1>Consenso all'uso dei Cookie</h1>
              <p>
                Alla prima visita del sito, gli utenti vedranno un banner che richiede il consenso per l'uso dei cookie non essenziali. Gli utenti possono modificare le preferenze in qualsiasi momento tramite le impostazioni del browser.
              </p>

              <h1>Contatti</h1>
              <p>
                Per ulteriori informazioni sui cookie utilizzati da questo sito o per altre domande relative alla privacy, è possibile contattarci all'indirizzo email: <a href="mailto:privacy@fbwebstudio.it">privacy@fbwebstudio.it</a>.
              </p>

              <p><small>Ultimo aggiornamento: Settembre 2024</small></p>

            </Text>
          </ContentWithPaddingXl>
        </Container>
        <Footer />
      </AnimationRevealPage>
  );
};
