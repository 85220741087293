import React, { useEffect, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, Content2Xl } from "components/misc/Layouts";
import tw from "twin.macro";
// eslint-disable-next-line
import { css } from "styled-components/macro";
import GitHubButton from "react-github-btn";
import { LogoLink } from "components/headers/light.js";
import { SectionHeading as HeadingBase } from "components/misc/Headings";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";
import logo from "images/logo.svg";
import {BookingAPI} from "api";
import {useNavigate, useParams} from "react-router-dom";
import {Spinner} from "reactstrap";
import {Footer, Header, Loading} from "custom";

/* Hero */
const Row = tw.div`flex`;
const NavRow = tw(Row)`flex flex-col lg:flex-row items-center justify-between`;
const NavLink = tw.a`mt-4 lg:mt-0 transition duration-300 font-medium pb-1 border-b-2 lg:mr-12 last:mr-0 text-gray-700 border-gray-400 hocus:border-gray-700 `;
const PrimaryNavLink = tw(
    NavLink
)`text-gray-100 bg-primary-500 px-6 py-3 border-none rounded hocus:bg-primary-900 focus:shadow-outline`;

const SecondaryNavLink = tw(
    NavLink
)`text-gray-100 bg-secondary-500 px-6 py-3 border-none rounded hocus:bg-secondary-900 focus:shadow-outline`;

const TertiaryNavLink = tw(
    NavLink
)`text-gray-100 bg-gray-900 px-6 py-3 border-none rounded hocus:bg-gray-900 focus:shadow-outline`;

const HeroRow = tw(Row)`max-w-xl flex-col justify-between items-center py-20 lg:py-24 mx-auto`;

const Heading = tw(HeadingBase)`text-center text-primary-900 leading-snug`;
const Description = tw(DescriptionBase)`mt-4 text-center lg:text-base text-gray-700 max-w-lg mx-auto lg:mx-0`;
const Description2 = tw(DescriptionBase)`mt-4 text-center text-lg text-gray-700 max-w-lg mx-auto lg:mx-0`;

const LoadingContainer = tw.div`flex justify-center items-center h-full`;

const moment = require("moment")

export default function BookingConfirmation() {
  const [booking, setBooking] = useState(null);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate()

  const {hash_booking} = useParams();

  useEffect(() => {
    BookingAPI.getDetail(hash_booking).then((data) => {
          setBooking(data);
          setLoading(false);
        }).catch((err) => {
          console.error("Errore nel caricamento dei dettagli della prenotazione:", err);
          navigate("/")
        });
  }, []);

  if (loading) {
    return (
        <Loading />
    );
  }


  const { company, service, operator, date_start, location, phone_company, slug_company } = booking;
  const formattedDate = moment(date_start).format('DD/MM/YYYY');
  const formattedTime = moment(date_start).format('HH:mm');

  return (
      <AnimationRevealPage disabled>
        <Container tw="-mx-8 -mt-8 pt-8 px-8">
          <Content2Xl>
            <Header />
            <HeroRow>
              <Heading>Prenotazione Confermata!</Heading>
              <Description2 tw="mt-12">
                Ti confermiamo la tua prenotazione presso <strong>{company}</strong>.
              </Description2>
              <div tw="mt-12 text-center">
                <Description>
                  Di seguito un riepilogo della tua prenotazione:
                </Description>
                <ul tw="mt-8 text-left text-lg space-y-4">
                  <li><strong>Servizio:</strong> {service} ({operator})</li>
                  <li><strong>Data:</strong> {formattedDate}</li>
                  <li><strong>Orario:</strong> {formattedTime}</li>
                  <li><strong>Indirizzo:</strong> {location.address}, {location.city}</li>
                </ul>
              </div>
              <div tw="mt-12 text-center flex flex-col lg:flex-row items-center space-y-4 lg:space-y-0 lg:space-x-8">
                <PrimaryNavLink target="_blank" href={`https://maps.google.com/?q=${location.lat},${location.lng}`}>
                  Raggiungi
                </PrimaryNavLink>
                <SecondaryNavLink target="_blank" href={`tel:${phone_company}`}>
                  Chiama
                </SecondaryNavLink>
                <br />
                <TertiaryNavLink onClick={() => navigate("/" + slug_company)}>
                  Torna a {company}
                </TertiaryNavLink>
              </div>
            </HeroRow>
          </Content2Xl>
          <Footer />
        </Container>
      </AnimationRevealPage>
  );
}
